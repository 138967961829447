<template>
    <div @click.prevent="showStopAppModal" class="w-100">
        <slot></slot>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'

export default {
    name: 'StopApplication',
    mixins: [enumsData],
    props: {
        app: Object,
        appName: String,
        sessionId: String,
        isShared: Boolean
    },
    methods: {
        showStopAppModal() {
            if ((this.app.aid && this.$route.query.stopAid !== this.app.aid) || (this.sessionId && this.$route.query.stopSessionId !== this.sessionId)) {
                this.$router.push({ query: { stopAid: this.app.aid, stopSessionId: this.sessionId } })
            }

            const spaceType = this.app.space_type || this.currentSpaceType

            let message = `Are you sure you would like to stop application ${this.appName}?`
            if (this.isShared) {
                message = `Are you sure you would like to stop application ${this.appName}?<p class="my-2 font-weight-bold warning--text">This application allows multiple users to connect simultaneously, so stopping it might affect other users.</p> <p>It will stop automatically once idle.</p>`
            }
            if (
                spaceType.toString() === this.spaceTypes.EDUCATION_SPACE.toString() &&
                !this.isMasterInstance &&
                !this.isDistributedInstance &&
                this.app.active_resource_runtime === 'node_pool'
            ) {
                message = `The current application was started by the instructor with GPU resources. Students are not allowed to stop such applications, as they cannot start the application with GPU resources themselves.`
            }

            let actions = ['stop-application', 'cancel']
            if (
                spaceType.toString() === this.spaceTypes.EDUCATION_SPACE.toString() &&
                !this.isMasterInstance &&
                !this.isDistributedInstance &&
                !this.isSpaceAdmin &&
                this.app.active_resource_runtime === 'node_pool'
            ) {
                actions = ['cancel']
            }

            this.$store.dispatch('showGlobalDialog', {
                dialogText: message,
                dialogAction: actions,
                dialogTitle: 'Confirm application stop'
            })
        }
    },
    computed: {
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'currentSpaceType']),
        ...mapGetters('instanceStore', ['isMasterInstance', 'isDistributedInstance'])
    }
}
</script>
